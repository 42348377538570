import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import MakeHtml from '../components/commons/MakeHtml';
import ActivitiesList from '../components/activities/ActivitiesList';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import AnimeLogo from '../components/commons/AnimeLogo';

export function IndexPageTemplate({
	headerBloc,
	footerBloc,
	activitiesList,
	imageDeux,
	imageTrois,
	preview = false,
}) {
	return (
		<>
			<AnimeLogo />
			<ParallaxProvider>
				<ParallaxBanner
					layers={[
						{
							image: `${
								!!headerBloc.contentImage.image.childImageSharp
									? headerBloc.contentImage.image.childImageSharp.fluid.src
									: headerBloc.contentImage.image
							}`,
							amount: headerBloc.contentImage.parallaxSpeed,
							props: {
								style: { backgroundPosition: `${headerBloc.contentImage.positionnementImage}` },
							},
						},
					]}
					style={{
						height: '100%',
						padding: '100px 0',
					}}
				>
					<div className="medium-contain">
						<article className="text-in-full-img">
							<MakeHtml text={headerBloc.text} />
							<Link
								to={headerBloc.link}
								title="En Savoir plus sur ECEO"
								className="button back-btn"
							>
								En savoir plus
							</Link>
						</article>
					</div>
				</ParallaxBanner>

				<div className="white-line" />
				<ParallaxBanner
					layers={[
						{
							image: `${
								!!imageDeux.contentImage.image.childImageSharp
									? imageDeux.contentImage.image.childImageSharp.fluid.src
									: imageDeux.contentImage.image
							}`,
							amount: imageDeux.contentImage.parallaxSpeed,
							props: {
								style: { backgroundPosition: `${imageDeux.contentImage.positionnementImage}` },
							},
						},
					]}
					style={{
						height: '300px',
						padding: '100px 0',
					}}
				></ParallaxBanner>

				{!preview ? (
					<div className="activities-list-wrapper">
						<div className="medium-contain activities-list">
							<ActivitiesList order={activitiesList} />
						</div>
					</div>
				) : (
					<p>Liste des activités non visible en preview !</p>
				)}

				<ParallaxBanner
					layers={[
						{
							image: `${
								!!imageTrois.contentImage.image.childImageSharp
									? imageTrois.contentImage.image.childImageSharp.fluid.src
									: imageTrois.contentImage.image
							}`,
							amount: imageTrois.contentImage.parallaxSpeed,
							props: {
								style: { backgroundPosition: `${imageTrois.contentImage.positionnementImage}` },
							},
						},
					]}
					style={{
						height: '300px',
						padding: '100px 0',
					}}
				></ParallaxBanner>
				<div className="white-line" />

				<ParallaxBanner
					layers={[
						{
							image: `${
								!!footerBloc.contentImage.image.childImageSharp
									? footerBloc.contentImage.image.childImageSharp.fluid.src
									: footerBloc.contentImage.image
							}`,
							amount: footerBloc.contentImage.parallaxSpeed,
							props: {
								style: { backgroundPosition: `${footerBloc.contentImage.positionnementImage}` },
							},
						},
					]}
					style={{
						height: '100%',
						padding: '100px 0',
					}}
				>
					<div className="medium-contain">
						<article className="text-in-full-img footer-in-full-img">
							<MakeHtml text={footerBloc.text} />
							<Link
								to={footerBloc.link}
								title="En Savoir plus sur ECEO"
								className="button back-btn"
							>
								En savoir plus
							</Link>
						</article>
					</div>
				</ParallaxBanner>
			</ParallaxProvider>

			<style jsx>{`
				.medium-contain {
					position: relative;
				}
				.full-img-header {
					z-index: 1;
				}
				.full-img-footer {
					z-index: 3;
				}
				.activities-list-wrapper {
					z-index: 2;
					background: white;
				}
				.full-img-wrapper {
					min-height: calc(100vh - 124px);
				}
				.full-img {
					height: 100%;
					background-position: ${headerBloc.contentImage.positionnementImage};
					position: absolute;
					top: 0;
					left: 0;
				}
				.activities-list {
					display: flex;
					flex-flow: row wrap;
					align-items: flex-start;
					justify-content: space-around;
					padding: 100px 20px;
					box-sizing: border-box;
				}
				.footer-bloc-full-img {
					background-position: ${footerBloc.contentImage.positionnementImage};
				}
				.text-in-full-img {
					justify-content: ${headerBloc.contentImage.positionnementBloc};
				}
				.footer-in-full-img {
					justify-content: ${footerBloc.contentImage.positionnementBloc};
				}
			`}</style>
		</>
	);
}

export default function IndexPage({ data }) {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout title={frontmatter.seo.seoTitle} description={frontmatter.seo.seoDescription} index>
			<IndexPageTemplate
				headerBloc={frontmatter.headerBloc}
				footerBloc={frontmatter.footerBloc}
				activitiesList={frontmatter.activitiesList}
				imageDeux={frontmatter.imageDeux}
				imageTrois={frontmatter.imageTrois}
			/>
		</Layout>
	);
}

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				headerBloc {
					link
					text
					contentImage {
						altImage
						image {
							childImageSharp {
								fluid(maxWidth: 1922, quality: 90) {
									...GatsbyImageSharpFluid
								}
							}
						}
						positionnementImage
						positionnementBloc
						parallaxSpeed
					}
				}
				imageDeux {
					contentImage {
						image {
							childImageSharp {
								fluid(maxWidth: 1922, quality: 90) {
									...GatsbyImageSharpFluid
								}
							}
						}
						altImage
						positionnementImage
						parallaxSpeed
					}
				}
				activitiesList
				imageTrois {
					contentImage {
						image {
							childImageSharp {
								fluid(maxWidth: 1922, quality: 90) {
									...GatsbyImageSharpFluid
								}
							}
						}
						altImage
						positionnementImage
						parallaxSpeed
					}
				}
				footerBloc {
					link
					text
					contentImage {
						altImage
						image {
							childImageSharp {
								fluid(maxWidth: 1922, quality: 90) {
									...GatsbyImageSharpFluid
								}
							}
						}
						positionnementImage
						positionnementBloc
						parallaxSpeed
					}
				}
				seo {
					seoDescription
					seoTitle
				}
			}
		}
	}
`;
