import React from 'react';
import IconAnimeLogo from '../navigations/IconAnimeLogo';
import { useState } from 'react';
import { useEffect } from 'react';

export default function AnimeLogo() {
	const [opacity, setOpacity] = useState(false);
	const [animeLogo, setAnimeLogo] = useState(null);

	useEffect(() => {
		setAnimeLogo(sessionStorage.getItem('animeLogo'));
	}, []);

	return (
		<>
			{animeLogo === null && (
				<div className={`anime-logo-wrapper ${opacity ? 'opacity' : ''}`}>
					<div className="anime-logo-container">
						<IconAnimeLogo setAnimeLogo={setAnimeLogo} setOpacity={setOpacity} />
					</div>
				</div>
			)}
			<style jsx>{`
				.anime-logo-wrapper {
					z-index: 9999;
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					min-height: 100vh;
					background: white;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 1;
					transition: all 0.1s;
				}
				.opacity {
					opacity: 0;
				}
				/*voir global pour opacity*/
				.anime-logo-container {
					width: 100%;
					max-width: 700px;
					padding: 20px;
					box-sizing: border-box;
				}
			`}</style>
		</>
	);
}
