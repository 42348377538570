import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

function ActivitiesList({ order, data }) {
	const { nodes } = data.allMarkdownRemark;

	const newArray = [];

	for (const title of order) {
		newArray.push(nodes.find((n) => n.frontmatter.title === title));
	}

	return (
		<>
			{data && (
				<>
					{newArray.map(({ frontmatter, fields }) => (
						<article className="activity-bloc" key={`activities_list_article_${frontmatter.title}`}>
							<picture>
								<source
									srcSet={
										!!frontmatter.activityThumbnail.image.childImageSharp
											? frontmatter.activityThumbnail.image.childImageSharp.fixed.srcWebp
											: frontmatter.activityThumbnail.image
									}
									type="image/webp"
								/>
								<img
									src={
										!!frontmatter.activityThumbnail.image.childImageSharp
											? frontmatter.activityThumbnail.image.childImageSharp.fixed.src
											: frontmatter.activityThumbnail.image
									}
									alt={frontmatter.activityThumbnail.altImage}
									loading="lazy"
								/>
							</picture>
							<h1>{frontmatter.title}</h1>
							<p>{frontmatter.introductionIndex}</p>
							<div className="button-wrapper">
								<Link to={fields.slug} title={frontmatter.title} className="button back-btn">
									En savoir plus
								</Link>
							</div>
						</article>
					))}
				</>
			)}

			<style jsx>
				{`
					article {
						position: relative;
						width: 100%;
						max-width: 360px;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 60px 30px;
						min-height: 560px;
					}
					img {
						border-radius: 20px;
						width: 100%;
					}
					h1 {
						color: #89a112;
						font-size: 28px;
						text-align: center;
					}
					.button-wrapper {
						position: absolute;
						bottom: -45px;
						text-align: center;
					}
				`}
			</style>
		</>
	);
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query ActivitiesList {
				allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "activity-page" } } }) {
					nodes {
						frontmatter {
							activityThumbnail {
								altImage
								image {
									childImageSharp {
										fixed(width: 360, height: 220) {
											...GatsbyImageSharpFixed_withWebp
										}
									}
								}
							}
							introductionIndex
							title
						}
						fields {
							slug
						}
					}
				}
			}
		`}
		render={(data) => <ActivitiesList data={data} {...props} />}
	/>
);
